import React from 'react';
import Cookies from 'universal-cookie';
import Autocomplete from './reusableComponents/autocomplete';
import bulmaCalendar from 'bulma-calendar/dist/js/bulma-calendar.min';
import "bulma-calendar/dist/css/bulma-calendar.min.css"
import "./home.css";
import { getUserDataService } from '../userDataService';
import MultilineTextarea from './reusableComponents/textarea';
import Calendar from './reusableComponents/calendar';
import NavBar from './reusableComponents/navbar';

class Home extends React.Component {
  state = {
    error: undefined,
    text: undefined,
    token: undefined,
    selectedRange: []
  };

  constructor(props) {
    super(props);

    this.numberOfGuests = React.createRef();

    this.dateRangeSelected.bind(this);
    this.searchLocations.bind(this);
  }

  getMembersData = () => {
    fetch('/DE_filtered.txt')
      .then(response => response.text())
      .then(data => {
        console.log(data);
        let rows = data.split('\n').map(r => r.split('\t'));
        this.setState({ geoLoc: rows });
      });
  }

  componentWillMount() {
    this.getMembersData();
    if ('URLSearchParams' in window) {
      var searchParams = new URLSearchParams(window.location.search);
      this.setState({selectedLocation: [searchParams.get("lat"), searchParams.get("lon"), searchParams.get("zoom"), searchParams.get("city")]});
    }
  }

  selectCity(cityName) {
    const selection = this.state.geoLoc.find(r => r[0] === cityName);
    const zoom = Math.min(11, parseFloat(selection[3] + 1) * 2.5);
    this.setState({selectedLocation: [selection[1], selection[2], zoom, cityName]});
    if ('URLSearchParams' in window) {
      var searchParams = new URLSearchParams(window.location.search)
      searchParams.set("city", cityName);
      searchParams.set("lat", selection[1]);
      searchParams.set("lon", selection[2]);
      searchParams.set("zoom", zoom);
      var newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
      window.history.replaceState(null, '', newRelativePathQuery);
    }
    getUserDataService().updateCity(cityName, selection[1], selection[2], zoom);
  }

  searchLocations() {
    if ('URLSearchParams' in window) {
      var searchParams = new URLSearchParams(window.location.search)
      if(!searchParams.get("rangeStart") || !searchParams.get("rangeEnd") || !searchParams.get("lat") || !searchParams.get("lon")){
        return;
      }
      var newRelativePathQuery = "/search" + '?' + searchParams.toString();
      window.history.pushState(null, '', newRelativePathQuery);
      this.props.history.push(newRelativePathQuery);
    }
  }

  dateRangeSelected(range) {
    this.setState({selectedRange: range});
    if ('URLSearchParams' in window) {
      var searchParams = new URLSearchParams(window.location.search)
      if(range.length > 0){
        searchParams.set("rangeStart", range[0].getTime());
      }
      if(range.length > 1){
        searchParams.set("rangeEnd", range[1].getTime());
      }
      var newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
      window.history.replaceState(null, '', newRelativePathQuery);
    }
  }

  render() {
    return [
      <NavBar></NavBar>,
      <section id="searchBox" className="section">
        <div class="field">
          <label class="label">Wo?</label>
          <div class="control">
          {this.state.geoLoc ? 
            <Autocomplete initialValue={this.state.selectedLocation[3]?this.state.selectedLocation[3]:undefined} width="100%" placeholder="Ort" onSelection={(sel) => {this.selectCity(sel)}} data={this.state.geoLoc.map(r => r[0])} /> 
            : 
            <div class="field" style={{ width: "100%", display: "inline-block" }}>
              <div class="control is-loading">
                <input class="input" type="text" placeholder="Ort" />
              </div>
            </div>}
          </div>
        </div>
        <div class="field">
          <label class="label">Wann?</label>
          <div class="control">
            	<Calendar onDateRangeSelected={(range) => this.dateRangeSelected(range)}/>
          </div>
        </div>
        <div class="field">
          <div class="control">
            <button disabled={this.state.selectedRange.length != 2} onClick={()=>this.searchLocations()} class="button is-primary is-fullwidth">Finde Date-Vorschläge</button>
          </div>
        </div>
      </section>];
      /*<div class="buttons">
            <button class="button">Diese Woche</button>
            <button class="button">Nächstes Wochenende</button>
            <button class="button">Diese Woche</button>
            <button class="button">Irgendwann</button>
          </div>*/
  }
}

export default Home;
