import {BASE_PATH} from "./utils";

let _instance = undefined;

export function getUserDataService(){
  return _instance; 
}

class UserDataService {

  constructor(callback, history) {
    _instance = this;
    this.callback = callback;
    this.history = history;
    
    let params = new URLSearchParams(window.location.search);
    setTimeout(()=>{
      if(params.get("city") !== null){
        this.updateCity(decodeURI(params.get("city")), params.get("lon"), params.get("lat"), params.get("zoom"));
      }
      if(params.get("numberOfGuests") !== null){
        this.updateNumberOfGuests(params.get("numberOfGuests"));
      }
    },1);

  }

  updateState(state){
    this.callback({user: state});
  }

  updateNumberOfGuests(numberOfGuests){
    if(!numberOfGuests || numberOfGuests === null || isNaN(numberOfGuests)){
      numberOfGuests = undefined;
    }
    this.callback({numberOfGuests: parseInt(numberOfGuests)});
  }

  updateCity(name, lon, lat, zoom){
    this.callback({city:{name: name, lon: parseFloat(lon), lat: parseFloat(lat), zoom: parseFloat(zoom)}});
  }

  search(history, name, lon, lat, zoom, numberOfGuests){
    let nog = parseInt(numberOfGuests);
    if(isNaN(nog)){
      nog = undefined;
    }
    this.updateNumberOfGuests(nog);
    this.updateCity(name, lon, lat, zoom);
    history.push("/search?lat=" + lat + "&lon=" + lon + "&zoom=" + zoom + "&city=" + encodeURI(name)+(nog?"&numberOfGuests="+nog:""));
  }

}

export default UserDataService;
