import React from 'react';
import './navbar.css';

class NavBar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        };

        this.navigateHome = this.navigateHome.bind(this);
    }

    navigateHome(){
      var searchParams = new URLSearchParams(window.location.search);
      var newRelativePathQuery = "/" + '?' + searchParams.toString();
      window.history.pushState(null, '', newRelativePathQuery);
      this.props.history.push(newRelativePathQuery);
    }

    render() {
        return <nav class="navbar is-fixed-top">
        <div class="navbar-brand">
        <a class="navbar-item" href="#" onClick={()=>{this.navigateHome();}}>
          <img src="/media/datedatelogo.svg" alt="Datedate.de" width="80" height="24"/>
          <span style={{fontSize: "1.2rem"}}>DateDate.de</span>
        </a>
      </div>
    
      <div id="navBarMenu" class="navbar-menu">
        <div class="navbar-start">
        </div>
    
        <div class="navbar-end">
          <div class="navbar-item">
              </div>
        </div>
      </div>
    </nav>;
    }
}

export default NavBar;
