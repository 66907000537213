import React, { Component } from 'react';
import Cookies from 'universal-cookie';
import GoogleMapReact from 'google-map-react';
import { Icon } from '@iconify/react';
import locationIcon from '@iconify/icons-mdi/bank';
import homeIcon from '@iconify/icons-mdi/home';
import castleIcon from '@iconify/icons-mdi/castle';
import ccIcon from '@iconify/icons-mdi/account-group';
import LocationEntry from './reusableComponents/locationentry';
import { BASE_PATH } from "../utils";

import './search.css';
import NavBar from './reusableComponents/navbar';

class Search extends React.Component {
  state = {
    currentPage: 0,
    entriesPerPage: 20,
  };

  lastKnownScrollPosition = 0;
  scrollEventTimeOut;

  constructor(props) {
    super(props);
    this._onBoundsChange = this._onBoundsChange.bind(this);
    this.hoverLocation = this.hoverLocation.bind(this);
    this.gotoPage = this.gotoPage.bind(this);
    this.scrollHandler = this.scrollHandler.bind(this);

  }

  componentDidMount() {
    document.addEventListener('scroll', this.scrollHandler);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.scrollHandler);
  }

  scrollHandler(e) {
    clearTimeout(this.scrollEventTimeOut);
    setTimeout(() => {
      const element = document.getElementById('resultList');
      const listPosition = element.scrollTop + element.offsetTop - 60;
      if (window.scrollY > 0 && window.scrollY < listPosition) {
        if (this.lastKnownScrollPosition < window.scrollY) {
          window.scrollTo(0, listPosition);
        } else if (this.lastKnownScrollPosition > window.scrollY) {
          window.scrollTo(0, 0);
        }
      }
    }, 100);
    this.lastKnownScrollPosition = window.scrollY;
  }

  getMembersData = () => {
    const cookies = new Cookies();
    const token = cookies.get('auth');
    if (token) {
      const authHeaders = {
        headers: { 'x-auth': token }
      };

    }
  }

  fetchLocationData(minlat, maxlat, minlon, maxlon, page = this.state.currentPage) {
    var searchParams = new URLSearchParams(window.location.search);
    debugger;
    this.setState({ loading_entries: true });
    fetch(BASE_PATH + '/backend/locations.php?lat1=' + minlat + '&lon1=' + minlon + '&lat2=' + maxlat + '&lon2=' + maxlon + '&page=' + page+'&rangeStart='+searchParams.get("rangeStart")+'&rangeEnd='+searchParams.get("rangeEnd"))
      .then(response => response.json())
      .then(data => {
        console.log(data);
        this.setState({ locations: data.entries, currentPage: page, pageCount: Math.ceil(data.entry_count / data.entries_per_page), entryCount: data.entry_count, loading_entries: false });
      }).catch(reason => console.log("fetching location data failed", reason));
  }

  hoverLocation(element_id) {
    this.setState({ hoverId: element_id });
  }

  previewLocation(element_id) {
    if (this.state.previewId === element_id) {
      this.setState({ previewId: undefined });
    } else {
      this.setState({ previewId: element_id });
    }
  }

  gotoPage(page, scroll = false) {
    const pageNumber = this.state.pageCount;
    if (page < 0) { return; }
    if (page >= pageNumber) { return }
    this.setState({ currentPage: page });
    this.fetchLocationData(this.state.minlat, this.state.maxlat, this.state.minlon, this.state.maxlon, page);
    if (scroll) {
      const element = document.getElementById('resultList');
      const scrollTo = element.scrollTop + element.offsetTop;
      window.scrollTo(0, scrollTo - 60);
    }
  }

  componentWillMount() {
    this.getMembersData();
  }

  _onBoundsChange(center, zoom, bounds, marginBounds) {
    console.log(center, zoom, bounds, marginBounds)
    this.center = center;
    this.zoom = zoom;
    const minlat = Math.min(bounds[0], bounds[2]);
    const maxlat = Math.max(bounds[0], bounds[2]);
    const minlon = Math.min(bounds[1], bounds[3]);
    const maxlon = Math.max(bounds[1], bounds[3]);
    this.setState({ minlat: minlat, maxlat: maxlat, minlon: minlon, maxlon: maxlon, currentPage: 0 });
    this.fetchLocationData(minlat, maxlat, minlon, maxlon);
  }

  getLocationsPagination() {
    if (!this.state.locations) {
      return "loading ...";
    }
    const entries = [];
    const pageNumber = this.state.pageCount;
    let lastButtonExists = true;
    for (let i = 0; i < pageNumber; i++) {
      if (i === 0 || i === pageNumber - 1 || Math.abs(i - this.state.currentPage) <= 1) {
        if (!lastButtonExists) {
          lastButtonExists = true;
          entries.push(<li>
            <span className="pagination-ellipsis">&hellip;</span>
          </li>);
        }
        entries.push(<li key={"pageButton" + i}>
          <a onClick={() => { this.gotoPage(i, true); }} className={"pagination-link " + (i === this.state.currentPage ? " is-current " : "")} aria-label={"Seite " + (i + 1)}>{i + 1}</a>
        </li>);
      } else {
        lastButtonExists = false;
      }
    }


    return <div className="pagination is-centered" role="navigation" aria-label="pagination">
      <a className="pagination-previous" disabled={this.state.currentPage === 0} onClick={() => { this.gotoPage(this.state.currentPage - 1, true); }}>Vorherige Seite</a>
      <ul className="pagination-list">
        {entries}
      </ul>
      <a className="pagination-next" disabled={this.state.currentPage === pageNumber - 1 || pageNumber === 0} onClick={() => { this.gotoPage(this.state.currentPage + 1, true); }}>Nächste Seite</a>
    </div>;
  }


  selectLocation(id) {
    window.open("/entry/" + id, '_blank');
  }

  render() {
    const self = this;
    const locations = [];
    if (this.state.locations) {
      let count = 0;
      this.state.locations.filter((e, i) => e.lat && e.lon).forEach(element => {
        count++;
        if (count > 50) {
          return;
        }
        locations.push(<LocationPin
          onHover={(tog) => { if (tog) { this.hoverLocation(element.id) } else { this.hoverLocation(-1) } }}
          onCardClick={() => { this.selectLocation(element.id); this.setState({ previewId: undefined }) }}
          hovered={element.id === this.state.hoverId}
          previewed={element.id === this.state.previewId}
          lat={element.lat}
          lng={element.lon}
          location={element}
          key={element.id}
        />);
      });
    }

    const urlparams = new URLSearchParams(window.location.search);
    const hashparams = window.top.location.hash.substr(1).split("@");
    const location = {
      lat: 48.8959641595212,
      lng: 9.188380780045415,
    }
    let zoomLevel = 3;
    if (urlparams.get("lat")) {
      location.lat = parseFloat(urlparams.get("lat"));
      location.lng = parseFloat(urlparams.get("lon"));
      zoomLevel = parseFloat(urlparams.get("zoom"));
      /*locations.push(<HomePin
        onHover={()=>{}}
        lat={location.lat}
        lng={location.lng}
        text={""}
      />)*/
    }

    const locationsList = [];
    if (this.state.locations) {
      let count = 0;
      this.state.locations.filter((e, i) => e.lat && e.lon).forEach(element => {
        count++;
        if (count > 100) {
          return;
        }
        locationsList.push(<LocationEntry location={element} numberOfGuests={this.props.numberOfGuests} locationSelected={() => { window.open(element.link, '_blank').focus(); }} active={element.id === this.state.hoverId || element.id === this.state.previewId} onEnter={() => this.hoverLocation(element.id)} onLeave={() => this.hoverLocation(-1)} />)
      });
    }

    return [<NavBar></NavBar>,
      <div className='search-container'>
        <section className="section search-map">
          <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyD-0BG7Ow9YwLIZHwkHE35IvJqZIdq_9Dg' }}
            defaultCenter={location}
            defaultZoom={zoomLevel}
            onBoundsChange={this._onBoundsChange}
            options={{ gestureHandling: "greedy", fullscreenControl: false }}
            onChildClick={(childKey, child) => {
              self.previewLocation(child.location.id);
            }}
            onClick={() => { this.setState({ previewId: undefined }) }}
          >
            {locations}
          </GoogleMapReact>
        </section>
        <section id="resultList" className="card section search-list">
          {locationsList}
          {this.getLocationsPagination()}
        </section>
      </div>
    ];
  }
}

class LocationPin extends Component {

  render() {
    let icon = locationIcon;
    if (this.props.location && this.props.location.location_type === 2) {
      icon = castleIcon;
    }
    if (this.props.location && this.props.location.location_type === 3) {
      icon = ccIcon;
    }

    const location = this.props.location;

    let imageStyle = {};
    if(location.images && location.images.length > 0){
      imageStyle["backgroundImage"] = "url(\"/location_image.php?id="+location.images[0].id+"&thumb\")";
    }

    return <div onMouseEnter={() => { this.props.onHover(true); }} onMouseLeave={() => this.props.onHover(false)} className={"pin " + (this.props.hovered || this.props.previewed ? "has-background-link" : "") + (this.props.previewed ? " is-foreground-2 " : (this.props.hovered ? " hovered " : ""))}>
      <Icon icon={icon} color={(this.props.hovered || this.props.previewed ? "white" : "black")} className="pin-icon" />
      {this.props.hovered && !this.props.previewed ? <div className={"pin-name-tag " + (this.props.hovered ? "hovered" : "hidden")}>{this.props.location.title}</div> : ""}
      {this.props.previewed ? <div onClick={(e) => { this.props.onCardClick(); e.stopPropagation(); }} className={"card preview-card is-foreground-2"}><div className="preview-card-image" style={imageStyle}></div><p className="title is-6 preview-card-content  mb-0">{this.props.location.title}</p></div> : ""}
    </div>;
  }
}

class HomePin extends Component {
  render() {
    return <div onMouseEnter={() => { this.props.onHover(true); }} onMouseLeave={() => this.props.onHover(false)} className={"pin has-background-info" + (this.props.hovered ? "has-background-link" : "")}>
      <Icon icon={homeIcon} color={"white"} className="pin-icon" />
      <p className="pin-text">{this.props.children}</p>
    </div>;
  }
}

export default Search;
