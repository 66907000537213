import React from 'react';
import Cookies from 'universal-cookie';
import axios from 'axios';
import Autocomplete from './reusableComponents/autocomplete';
import { Redirect, Link } from 'react-router-dom';
import LocationEntry from './reusableComponents/locationentry';

class User extends React.Component {
  state = {
    error: undefined,
    text: undefined,
    token: undefined
  };

  render() {
    if (this.props.user === undefined || Object.keys(this.props.user).length === 0) {
      return <div className="is-flex-grow-1">loading ...</div>;
    } else if (this.props.user === false) {
      return <div className="is-flex-grow-1"><Redirect to="/login"></Redirect></div>;
    }

    let mylocations = [];
    if (this.props.user.locations.length === 0) {
      mylocations.push(<a className="panel-block is-active">
        <i>Noch keine Locations hinterlegt</i>
      </a>);
    } else {
      for (const loc of this.props.user.locations) {
        /*mylocations.push(<Link to={"/entry/" + loc.location_id}><a className="panel-block is-active">
          <span className="icon is-left">
            <i className="fas fa-map-marker-alt" aria-hidden="true"></i>
          </span>{loc.name}
        </a></Link>);*/
        mylocations.push(<LocationEntry numberOfGuests={this.props.numberOfGuests} location={loc} locationSelected={() => { this.props.history.push("/entry/" + loc.location_id) }} />);
      }
    }

    let admin = "";
    if (this.props.user.user_type === -1) {
      admin = <section className="section container is-max-desktop">
        <h1 className="title is-2">Admin-Bereich</h1>

        <h2 className="title is-4">Location Todo-Liste</h2>
        <nav class="panel">
          <div class="panel-block">
            <p class="control has-icons-left">
              <input class="input" type="text" placeholder="Search" />
              <span class="icon is-left">
                <i class="fas fa-search" aria-hidden="true"></i>
              </span>
            </p>
          </div>
          <p class="panel-tabs">
            <a class="is-active">Todo</a>
            <a>Doing</a>
            <a>Done</a>
          </p>
          <a class="panel-block is-active">
            <span class="panel-icon">
              <i class="fas fa-book" aria-hidden="true"></i>
            </span>
            bulma
          </a>
          
          <div class="panel-block">
            <button class="button is-link is-outlined is-fullwidth">
              Reset all filters
    </button>
          </div>
        </nav>
        <a className="button is-link is-fullwidth mt-4">
          Neuen Eintrag hinterlegen
        </a>
      </section>;
    }

    return (
      <div className="is-flex-grow-1">
        <section className="section container is-max-desktop">
          <h1 className="title is-2">Hallo {this.props.user.first_name}!</h1>

          <h2 className="title is-4">Deine Locations</h2>
          {mylocations}
          <Link to="/entry/new" className="button is-link is-fullwidth mt-4">
            Neue Location hinzufügen
          </Link>
        </section>
        {admin}
      </div>
    );
  }
}

export default User;
