import React from 'react';
import './calendar.css';

function isDateAAfterB(a, b){
    const ac = new Date(a);
    const bc = new Date(b);
    ac.setHours(0,0,0,0);
    bc.setHours(0,0,0,0);
    
    return ac>bc;
}

class Calendar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            displayDate: new Date(),
            selectedRange: []
        };

        this.switchMonth = this.switchMonth.bind(this);
        this.selectDate = this.selectDate.bind(this);
    }

    selectDate(date){
        if(this.state.selectedRange.length >= 2){
            this.setState({selectedRange: [date]});
            this.props.onDateRangeSelected([date]);
        }else{
            let newSel = [...this.state.selectedRange, date];
            newSel = newSel.sort((a,b)=>a.getTime()-b.getTime());
            this.setState({
                selectedRange: newSel
            });
            this.props.onDateRangeSelected(newSel);
        }
        console.log(date);
    }

    switchMonth(diff){
        const newDate = this.state.displayDate;
        newDate.setMonth(newDate.getMonth()+diff);
        this.setState({
            displayDate: newDate 
        });
    }

    drawMonth(date){
        const weekdaysShort =["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"].map(e => <div className="calendar-weekdays">{e}</div>);
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        const rangeStart = this.state.selectedRange[0];
        const rangeEnd = this.state.selectedRange[1];
        const today = new Date();
        today.setHours(0,0,0,0);
        const days = Array.from({length: lastDay.getDate()}, (_, i) => i + 1).map(e=>{
            const curDate = new Date(date.getFullYear(), date.getMonth(), e);
            const isSelected = (rangeStart && (curDate.getTime() == rangeStart.getTime()) || (rangeEnd && curDate.getTime() == rangeEnd.getTime()));
            const isPast = curDate<today;
            let isInRange = false;
            if(rangeStart && rangeEnd){
                isInRange = curDate > rangeStart && curDate < rangeEnd;   
            }else if(rangeStart){
                
            }
            return <div className={"month-element "+(isPast?" month-day-past ":"")+(isSelected?" month-day-selected ":"")+(isInRange?" month-day-range ":"")} onClick={!isPast?()=>this.selectDate(curDate):undefined}><div className="month-day">{e}</div></div>;
        });
        const preFillers = Array.from({length: (7+firstDay.getDay()-1)%7}, () => <div className=""></div>);
        const postFillers = Array.from({length: 7-(days.length+preFillers.length)%7}, () => <div className=""></div>);
        const tableEntries = [...weekdaysShort, ...preFillers, ...days, ...postFillers];
        const tableRows = [];
        return <div className="month">
            {tableEntries}
        </div>;
    }

    render() {
        const months = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];
        const { label, name, placeholder } = this.props;
        //const { activeIndex, matches, query } = this.state;
        const currentDate = new Date();
        const currentMonth = this.drawMonth(this.state.displayDate);
        return <div className="calendar">
            <div className="calendar-head">
                <button className="button is-small mr-4" onClick={()=>this.switchMonth(-1)}>&lt;</button>
                <span className="calendar-month-name">{months[this.state.displayDate.getMonth()]} {this.state.displayDate.getFullYear()}</span>
                <button className="button is-small ml-4" onClick={()=>this.switchMonth(1)}>&gt;</button>
            </div>
            {currentMonth}
        </div>;
    }
}

export default Calendar;
