import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { getUserDataService } from '../userDataService';
import { BASE_PATH } from '../utils';
import Autocomplete from './reusableComponents/autocomplete';

class Header extends React.Component {
  state = {
    hamburgerToggle: ""
  };

  constructor(props) {
    super(props);

    this.getGeoLocData();

    this.logout = this.logout.bind(this);
    this.search = this.search.bind(this);
  }

  
  componentWillReceiveProps(nextProps) {
    console.log(nextProps);
    debugger;
    if(nextProps.value !== this.props.value){
      this.setState({value: nextProps.value});
    }
  }

  selectCity(cityName) {
    const selection = this.state.geoLoc.find(r => r[0] === cityName);
    const zoom = Math.min(11, parseFloat(selection[3] + 1) * 2.5);
    this.setState({selectedLocation: [selection[1], selection[2], zoom, cityName]});
    getUserDataService().updateCity(cityName, selection[1], selection[2], zoom);
  }

  getGeoLocData = () => {
    fetch('/DE_filtered.txt')
      .then(response => response.text())
      .then(data => {
        console.log(data);
        let rows = data.split('\n').map(r => r.split('\t'));
        this.setState({ geoLoc: rows });
      });
  }

  toggleBerger = (state) => {
    this.setState(() => ({ hamburgerToggle: this.state.hamburgerToggle ? "" : 'is-active' }))
  }

  logout() {
    fetch(BASE_PATH + '/logout.php', {
      method: 'POST'
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        getUserDataService().loadData();
        this.setState({
          hamburgerToggle: ""
        });
      });
  }

  search(){
    if(this.state.selectedLocation){
      getUserDataService().search(this.props.history, this.state.selectedLocation[3], this.state.selectedLocation[1], this.state.selectedLocation[0], this.state.selectedLocation[2], parseInt(this.props.numberOfGuests));
    }
  }

  render() {

    let searchBar = <div> <div className="topNavSearch">{this.state.geoLoc ? <Autocomplete initialValue={this.props.city?this.props.city.name:undefined} width="11rem" placeholder="Ort" onSelection={(sel) => {this.selectCity(sel)}} data={this.state.geoLoc.map(r => r[0])} /> : <div class="field" style={{ width: "11rem", display: "inline-block" }}>
      <div class="control is-loading">
        <input class="input" type="text" placeholder="Ort" />
      </div>
    </div>}

      <span style={{ display: "inline-block", marginRight: "0.2rem" }} className="control has-icons-right">
        <input placeholder="Anzahl Gäste" value={this.props.numberOfGuests} onChange={(e)=>{getUserDataService().updateNumberOfGuests(parseInt(e.target.value));}} style={{ width: "9rem" }} className="topNavInput input" />
        <span className="icon is-small is-right">
          <i class="fas fa-user-friends"></i>
        </span>
      </span>
      <button class="button is-primary" onClick={this.search}>
        <span class="icon is-small">
          <i class="fas fa-search"></i>
        </span>
      </button>
      </div> 
      <div className="topNavSearchButton">
      <button class="button is-primary" onClick={()=>{this.props.history.push("/")}}>
        <span class="icon is-small">
          <i class="fas fa-search"></i>
        </span>
        <span>Location suchen</span>
      </button>
      </div>
    </div>;

    return (
      <div>
        <nav className="navbar is-fixed-top">
          <div className="navbar-brand">
            <div className="navbar-item">
              <NavLink to="/">
                DateDate.de
              </NavLink>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

export default Header;
