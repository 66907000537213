import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Link, NavLink, Redirect} from 'react-router-dom';
import './App.css'
import 'bulma-calendar/dist/css/bulma-calendar.min.css'
import Cookies from 'universal-cookie';
import axios from 'axios';
// Routes
import Home from './components/home';
import Header from './components/header';
import Search from './components/search';
import Entry from './components/entry';
import Private from './components/private';
import Signup from './components/signup';
import Login from './components/login';
import User from './components/user';
import Settings from './components/settings';
import NotFound from './components/notFound';
import Impressum from './components/impressum';
import UserDataService from './userDataService';
import CookieConsent from './components/reusableComponents/cookieconsent';

// Currently App.js will contain the router.

let isAuthenticated = false;

// This function serves to tell the routes whether the user is autheticated ot not
// And manages the cookies.
const auth = {
  isAuthenticated: false,
  authenticate(token, cb) {
    this.isAuthenticated = true;
    this.token = token;
    // Sets cookie
    const cookies = new Cookies();
    cookies.set('auth', token, { path: '/' });
  },
  loggout(cb) {
    this.isAuthenticated = false
    // Delete Cookie
    const cookies = new Cookies();
    cookies.remove('auth', { path: '/' })
  }
}

class AppRouter extends Component {
  constructor() {
    super();
    const self = this;
    this.uds = new UserDataService((state)=>{
      setTimeout(()=>{self.setState(JSON.parse(JSON.stringify(state)))},1);
    });
  }

  state = {
    token: false,
    authenticated: false,
    user: {}
  };

  logOut = (token) => {
    auth.loggout(() => {
      console.log(`Logout`);
    });

  }

  // Gets data from forms to
  handelSubmit = (response) => {
    auth.authenticate(response.data.token,() => {
      console.log('Auth Triggered');
    });

    this.setState(() => ({
      authenticated: true,
      token: response.data.token,
      user: { email: response.data.email }
    }));
  }

  render() {
    return (
      <BrowserRouter>
        <div>
          <CookieConsent/>
          <Switch>
              <Route path="/" render={(props) => <Home {...props} numberOfGuests={this.state.numberOfGuests} user={this.state.user} token={this.state.token} />} exact/>
              <Route path="/search" render={(props) => <Search {...props} numberOfGuests={this.state.numberOfGuests} user={this.state.user} token={this.state.token} />} exact/>
              <Route path="/entry/:id" render={(props) => <Entry {...props} numberOfGuests={this.state.numberOfGuests} user={this.state.user} token={this.state.token} />} exact/>
              <Route path="/impressum" component={Impressum} exact/>
              <Route component={NotFound}/>
          </Switch>
          <footer className="footer">
            <div className="content has-text-centered">
              <p>
                <strong>datedate.de</strong><br/>
                <Link to="/impressum" className="is-link">Impressum</Link>
              </p>
            </div>
          </footer>
          </div>
      </BrowserRouter>
    )
  }
}

export default AppRouter;
