import React from 'react';

export const BASE_PATH = window.location.protocol+"//"+window.location.hostname;

export function linebreakToHTML(string){
    return string.split("\n").map(function(item, idx) {
        return (
            <span key={idx}>
                {item}
                <br/>
            </span>
         )
    })
}

export function htmlEnc(s) {
    return s.replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/'/g, '&#39;')
      .replace(/"/g, '&#34;');
  }

export function osmAdress2GermanAddress(a){
    let result = [];
    if(a.amenity){
        result.push(a.amenity);
    }
    if(a.road){
        let street = a.road;
        if(a.house_number){
            street += " "+a.house_number;
        }
        result.push(street);
    }
    if(a.postcode){
        let city = a.postcode+"";
        if(a.city){
            city += " "+a.city;
        }else if(a.town){
            city += " "+a.town;
        }else if(a.village){
            city += " "+a.village;
        }else if(a.suburb){
            city += " "+a.suburb;
        }
        result.push(city);
    }

    return result.join(", ");
}

export function osmAdress2meta(a, meta){
    if(a.postcode){
        meta["address_postcode"] = a.postcode+"";
    }else{
        delete meta["address_postcode"];
    }
    if(a.city){
        meta["address_city"] = a.city+"";
    }else{
        delete meta["address_city"];
    }
    if(a.town){
        meta["address_town"] = ""+a.town;
    }else{
        delete meta["address_town"];
    }
    if(a.village){
        meta["address_village"] = ""+a.village;
    }else{
        delete meta["address_village"];
    }
    if(a.suburb){
        meta["address_suburb"] = ""+a.suburb;
    }else{
        delete meta["address_suburb"];
    }
    if(a.county){
        meta["address_county"] = ""+a.county;
    }else{
        delete meta["address_county"];
    }
    if(a.state){
        meta["address_state"] = ""+a.state;
    }else{
        delete meta["address_state"];
    }
}

export const META_DATA_MAP = [
    {display_name: "Allgemein", type: "category", entries: [
        {key_value: "website", type: "text", display_name: "Website"},
        {key_value: "local_benefits", type: "boolean", display_name: "Vorteile für Ortsansässige"},
    ]},
    {display_name: "Location & Räumlichkeiten", type: "category", entries: [
        {key_value: "max_seats", type: "number", display_name: "Maximale Anzahl von Gästen"},
        {key_value: "location_cost_fixed", type: "number_range", suffix: "€", display_name: "Mietkosten"},
        {key_value: "parking_available", type: "select", display_name: "Parkplätze vorhanden", options: [{display_name: "ja"}, {display_name: "nein"}, {display_name: "ja + Anzahl", type: "number"}]},
        {key_value: "outside_area_available", type: "boolean", display_name: "Außenbereich vorhanden & nutzbar"},
        {key_value: "ac_available", type: "select", display_name: "Klimaanlage vorhanden", options: [{display_name: "ja"}, {display_name: "nein"}, {display_name: "teilweise"}]},
        {key_value: "is_exclusive", type: "select", display_name: "Keine Parallelveranstaltungen", options: [{display_name: "ja"}, {display_name: "nein"}, {display_name: "nach Absprache"}]},
        {key_value: "handicapped_accessible", type: "select", display_name: "Behindertengerecht", options: [{display_name: "ja"}, {display_name: "nein"}, {display_name: "teilweise"}]},
        {key_value: "further_properties", type: "text", display_name: "Weitere Ausstattungsmerkmale"}
    ]},
    {display_name: "Essen & Verpflegung", type: "category", entries: [
        {key_value: "catering_free_choice", type: "select", display_name: "Freie Caterer-Wahl", options: [{display_name: "ja"}, {display_name: "nein"}, {display_name: "eingeschränkt", type: "text"}]},
        {key_value: "kitchen_available", type: "boolean", display_name: "Küche vorhanden", options: [{display_name: "ja"}, {display_name: "nein"}, {display_name: "ja + Anzahl", type: "number"}]},
        {key_value: "catering_cost_per_guest", type: "number_range", suffix: "€", display_name: "Cateringkosten pro Gast"},
        {key_value: "drinks_included", type: "select", display_name: "Getränkekosten im Catering inbegriffen", options: [{display_name: "ja"}, {display_name: "nein"}, {display_name: "teilweise"}]}
    ]},
    {display_name: "Technische Ausstattung", type: "category", entries: [
        {key_value: "sound_system_available", type: "select", display_name: "Beschallungsanlage vorhanden", options: [{display_name: "ja"}, {display_name: "gegen Aufpreis"}, {display_name: "nein"}]},
        {key_value: "stage_available", type: "boolean", display_name: "Bühne vorhanden"}
    ]}
    
];



//{"key": "drinks_inclusive", "desc": "Getränke inklusive", icon: "fas fa-glass-cheers"},
export const PROPERTY_MAP = [
    {"key": "catering_inclusive", "desc": "Catering inklusive", icon: "fas fa-drumstick-bite"},
    {"key": "catering_free_choice", "desc": "freie Catererwahl", icon: "fas fa-drumstick-bite"},
    {"key": "near_water", "desc": "Am Wasser", icon: "fas fa-water"},
    {"key": "historic", "desc": "Historische Location", icon: "fab fa-fort-awesome"},
    {"key": "music_system", "desc": "Musikanlage", icon: "fas fa-volume-up"},
    {"key": "outside_area", "desc": "Außenbereich", icon: "fas fa-sun"},
    {"key": "air_conditioning", "desc": "Klimaanlage", icon: "fas fa-snowflake"},
    {"key": "accessible", "desc": "Behindertengerecht", icon: "fas fa-wheelchair"},
    {"key": "inhouse_wedding", "desc": "Standesamtliche Trauung vor Ort", icon: "fas fa-file-signature"},
    {"key": "inhouse_religious_wedding", "desc": "Kirchliche Trauung vor Ort", icon: "fas fa-church"},
    {"key": "parking", "desc": "Parkplätze", icon: "fas fa-car-side"},
    {"key": "stay", "desc": "Übernachtungsmöglichkeiten", icon: "fas fa-bed"},
    {"key": "kitchen", "desc": "Küche", icon: "fas fa-blender"}
];