import React from 'react';
import './locationentry.css';
import { PROPERTY_MAP} from '../../utils';

function numberWithCommas(x) {
  return x.toFixed(2).toString().replace(".", ",");
}

class LocationEntry extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }


  render() {
    const location = this.props.location;

    let imageStyle = {};
    if(location.images && location.images.length > 0){
      imageStyle["backgroundImage"] = "url(\"/location_image.php?id="+location.images[0].id+"&thumb\")";
    }else{
      imageStyle["backgroundImage"] = "url(\"/media/no_image.jpg\")";
    }

    //let tags = PROPERTY_MAP.filter(p => location.meta[p.key] === 1).map((p,i) => i>6?"":(i===6?<p> ... </p>:<span class="tag">{p.desc}</span>));
    let tags = [];

    let costSection = <p className="location-entry-cost-section"><span style={{
      display: "block",
      fontSize: "1.5rem",
      textAlign: "right"}}
      >?? - ?? €</span></p>;
    
    if(location.minPrice && location.maxPrice) {
      let priceString = numberWithCommas(location.minPrice)+" - "+numberWithCommas(location.maxPrice)+" €";
      if(location.minPrice < 0){
        priceString = "? €";
      }else if(location.minPrice == location.maxPrice){
        priceString = numberWithCommas(location.maxPrice)+" €";
      }
      costSection = <p className="location-entry-cost-section"><span style={{
        display: "block",
        fontSize: "1.5rem",
        textAlign: "right"}}
        >{priceString}</span></p>;
    }

    return (
      <div onClick={() => { this.props.locationSelected() }} className={"card location-list-entry " + ((this.props.active || this.state.hover) ? "has-background-link-light" : "")} onMouseEnter={() => { this.setState({ hover: true }); if (this.props.onEnter) { this.props.onEnter(); } }} onMouseLeave={() => { this.setState({ hover: false }); if (this.props.onEnter) { this.props.onLeave(); } }}>
        <div className="location-media-container" style={imageStyle}></div>
        <div className="location-info-container card-content">
        
          <p className="title is-6">{location.title}</p>
          <p className="subtitle is-6">{location.location}</p>
          <div className="location-entry-content">
            <div class="tags is-flex-grow-1 location-entry-tags">
              {tags}
            </div>
            {costSection}
          </div>
          
        </div>
      </div>
    );
  }
}

export default LocationEntry;
